// Root.tsx
import Navbar from "./Navbar";
import Footer from "./Footer";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "./features/AuthContext";

export default function Root() {
  const navigate = useNavigate();
  const auth = useAuth();

  const user = auth?.user;
  const token = auth?.token;
  const logout = auth?.logout;

  // Redirect if not authenticated
  useEffect(() => {
    if (auth && (!token || !user)) {
      navigate("/login", { replace: true });
    }
  }, [auth, token, user, navigate]);

  // Handle postMessage from opener
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== "http://localhost:3000") return;

      switch (event.data.action) {
        case "check_url":
        case "request_token_check":
        case "send_url":
          console.log("🔍 Sending updated URL to parent...");
          window.opener?.postMessage(
            { action: "updated_url", url: window.location.href },
            "http://localhost:3000"
          );
          break;
        case "logout":
          console.log("🔍 Logging out...");
          logout?.(); // ✅ Safe call
          break;
        default:
          break;
      }
    };

    if (window.opener) {
      console.log("🚀 Sending initial URL to parent...");
      window.opener.postMessage(
        { url: window.location.href },
        "http://localhost:3000"
      );
    }

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [logout]);

  return (
    <>
      {/* <Navbar /> */}
      <div id="detail">
        <Outlet />
      </div>
      {/* <Footer /> */}
    </>
  );
}
