import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { jwtDecode } from "jwt-decode";

interface JwtPayload {
  exp: number;
  userId: string;
  phone: string;
  role: string;
}

interface AuthContextType {
  user: {
    userId: string;
    phone: string;
    role: string;
  } | null;
  token: string | null;
  login: (token: string, userData: { userId: string; phone: string; role: string }) => void;
  logout: () => void;
  isAuthenticated: boolean;
}

const AuthContext = createContext<AuthContextType | null>(null);

// Utility to decode JWT and check if expired
const getDecodedToken = (token: string | null): JwtPayload | null => {
  try {
    if (!token) return null;
    const decoded: JwtPayload = jwtDecode(token);
    if (decoded.exp * 1000 < Date.now()) return null;
    return decoded;
  } catch {
    return null;
  }
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
  const [user, setUser] = useState<AuthContextType['user']>(null);

  useEffect(() => {
    const decoded = getDecodedToken(token);
    if (decoded) {
      setUser({
        userId: decoded.userId,
        phone: decoded.phone,
        role: decoded.role,
      });
    } else {
      logout(); // Remove invalid/expired token
    }
  }, [token]);

  const login = (
    newToken: string,
    userData: { userId: string; phone: string; role: string }
  ) => {
    localStorage.setItem('token', newToken);
    setToken(newToken);
    setUser(userData);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setToken(null);
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        login,
        logout,
        isAuthenticated: !!token && !!user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
