import React from 'react';
import { Button, Dialog } from '@headlessui/react';


interface AddTokenModalProps {
  isOpen: boolean;
  onClose: () => void;
  isAdmin: boolean;
  formData: {
    operationalToken: string;
    brokerName: string;
    tokenValue: string;
    targetUserId: string;
  };
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  submitToken: (tokenType: 'operational' | 'broker') => void;
}

// Broker options (can be moved to config or constant file)
const brokerOptions = [
  {
    value: 'zerodha',
    name: 'Zerodha',
    icon: '/icons/zerodha.png',
    caption: 'India’s largest broker',
  },
  {
    value: 'dhan',
    name: 'Dhan',
    icon: '/icons/dhan.png',
    caption: 'Fast & simple trading',
  },
  {
    value: 'angel',
    name: 'Angel One',
    icon: '/icons/angel.png',
    caption: 'Smart investing platform',
  },
  {
    value: 'groww',
    name: 'Groww',
    icon: '/icons/groww.png',
    caption: 'Investing made easy',
  },
];

const AddTokenModal: React.FC<AddTokenModalProps> = ({
  isOpen,
  onClose,
  isAdmin,
  formData,
  handleChange,
  submitToken,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <Dialog.Panel className="bg-white w-full max-w-2xl rounded-2xl shadow-xl p-6 space-y-6">
          <Dialog.Title className="text-2xl font-bold">Add Token</Dialog.Title>

          {/* Operational Token Section for Admins */}
          {isAdmin && (
            <div className="space-y-2 border-b pb-5">
              <h3 className="text-lg font-semibold">Operational Token</h3>
              <textarea
                name="operationalToken"
                className="w-full p-2 border rounded"
                placeholder="Enter Operational Token"
                value={formData.operationalToken}
                onChange={handleChange}
                rows={3}
              />
              <div className="flex justify-end">
                <Button onClick={() => submitToken('operational')}>Submit Operational Token</Button>
              </div>
            </div>
          )}

          {/* Trade Token Section */}
          <div className="space-y-2">
            <h3 className="text-lg font-semibold">Trade Token</h3>

            {/* Broker Icon Grid */}
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
              {brokerOptions.map((broker) => (
                <div
                  key={broker.value}
                  className={`cursor-pointer border rounded-lg p-3 flex flex-col items-center text-center shadow-sm transition-all duration-200 hover:shadow-md ${
                    formData.brokerName === broker.value
                      ? 'border-blue-500 ring-2 ring-blue-300'
                      : 'border-gray-300'
                  }`}
                  onClick={() =>
                    handleChange({
                      target: { name: 'brokerName', value: broker.value },
                    } as any)
                  }
                >
                  <img src={broker.icon} alt={broker.name} className="w-10 h-10 mb-2" />
                  <div className="font-medium">{broker.name}</div>
                  <div className="text-sm text-gray-500">{broker.caption}</div>
                </div>
              ))}
            </div>

            {/* Admin User ID Input */}
            {isAdmin && (
              <input
                name="targetUserId"
                type="text"
                className="w-full p-2 border rounded mt-4"
                placeholder="Target User ID (optional)"
                value={formData.targetUserId}
                onChange={handleChange}
              />
            )}

            {/* Token Input */}
            <textarea
              name="tokenValue"
              className="w-full p-2 border rounded mt-2"
              placeholder="Enter Trade Token"
              value={formData.tokenValue}
              onChange={handleChange}
              rows={3}
            />

            <div className="flex justify-end mt-4">
              <Button onClick={() => submitToken('broker')}>Submit Trade Token</Button>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default AddTokenModal;
