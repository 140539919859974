import { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../features/AuthContext';
import { host } from '../../utilities/util';

export function useLoginFlow() {
  const { login } = useAuth();
  const [step, setStep] = useState<'phone' | 'otp' | 'pin' | 'setPin'>('phone');
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleOtpRequest = async () => {
    setError('');
    setLoading(true);
    try {
      const { data } = await axios.post(`${host}request-otp`, { phone });
      if (data.status === 'success') setStep('otp');
      else setError(data.message);
    } catch {
      setError('Failed to send OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleOtpVerify = async () => {
    setError('');
    setLoading(true);
    try {
      const res = await axios.post(`${host}verify-otp`, { phone, otp });
      if (res.data.status === 'success') {
        const pinRes = await axios.post(`${host}has-pin`, { phone });
        setStep(pinRes.data.data.hasPin ? 'pin' : 'setPin');
      } else {
        setError(res.data.message);
      }
    } catch {
      setError('OTP verification failed.');
    } finally {
      setLoading(false);
    }
  };

  const handlePinVerify = async () => {
    setError('');
    setLoading(true);
    try {
      const res = await axios.post(`${host}verify-pin`, { phone, pin });
      if (res.data.status === 'success') {
        login(res.data.data.token, res.data.data.user);
      } else {
        setError(res.data.message);
      }
    } catch {
      setError('Login failed.');
    } finally {
      setLoading(false);
    }
  };

  const handlePinSet = async () => {
    setError('');
    setLoading(true);
    try {
      const res = await axios.post(`${host}set-pin`, { phone, pin });
      if (res.data.status === 'success') {
        handlePinVerify();
      } else {
        setError(res.data.message);
      }
    } catch {
      setError('Failed to set PIN.');
    } finally {
      setLoading(false);
    }
  };

  return {
    step,
    phone,
    otp,
    pin,
    error,
    loading,
    setPhone,
    setOtp,
    setPin,
    handleOtpRequest,
    handleOtpVerify,
    handlePinVerify,
    handlePinSet,
  };
}
