import React from 'react';
import { useLoginFlow } from './hooks/useLogin';

export default function LoginScreen() {
  const {
    step,
    phone,
    otp,
    pin,
    error,
    loading,
    setPhone,
    setOtp,
    setPin,
    handleOtpRequest,
    handleOtpVerify,
    handlePinVerify,
    handlePinSet,
  } = useLoginFlow();

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4 py-8">
      <div className="w-full max-w-md bg-white shadow-xl rounded-xl p-8">
        <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6">
          🔐 Welcome to <span className="text-indigo-700">waliachandan.com</span>
        </h2>

        {error && (
          <div className="bg-red-100 text-red-700 px-4 py-2 rounded mb-4 text-sm text-center border border-red-300">
            {error}
          </div>
        )}

        {step === 'phone' && (
          <>
            <label className="block text-sm text-gray-600 mb-2">Phone Number</label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter your phone"
              className="w-full border rounded px-3 py-2 mb-4 focus:outline-none focus:ring focus:border-gray-400"
            />
            <button
              onClick={handleOtpRequest}
              disabled={loading || !phone}
              className="w-full bg-gray-800 text-white py-2 rounded hover:bg-gray-700 transition disabled:opacity-50"
            >
              {loading ? 'Sending OTP...' : 'Send OTP'}
            </button>
          </>
        )}

        {step === 'otp' && (
          <>
            <label className="block text-sm text-gray-600 mb-2">OTP</label>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
              className="w-full border rounded px-3 py-2 mb-4 focus:outline-none focus:ring focus:border-gray-400"
            />
            <button
              onClick={handleOtpVerify}
              disabled={loading || otp.length < 6}
              className="w-full bg-gray-800 text-white py-2 rounded hover:bg-gray-700 transition disabled:opacity-50"
            >
              {loading ? 'Verifying...' : 'Verify OTP'}
            </button>
          </>
        )}

        {step === 'pin' && (
          <>
            <label className="block text-sm text-gray-600 mb-2">Enter PIN</label>
            <input
              type="password"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              placeholder="6-digit PIN"
              className="w-full border rounded px-3 py-2 mb-4 focus:outline-none focus:ring focus:border-gray-400"
            />
            <button
              onClick={handlePinVerify}
              disabled={loading || pin.length !== 6}
              className="w-full bg-green-600 text-white py-2 rounded hover:bg-green-700 transition disabled:opacity-50"
            >
              {loading ? 'Logging in...' : 'Login'}
            </button>
          </>
        )}

        {step === 'setPin' && (
          <>
            <label className="block text-sm text-gray-600 mb-2">Create PIN</label>
            <input
              type="password"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              placeholder="Set 6-digit PIN"
              className="w-full border rounded px-3 py-2 mb-4 focus:outline-none focus:ring focus:border-gray-400"
            />
            <button
              onClick={handlePinSet}
              disabled={loading || pin.length !== 6}
              className="w-full bg-indigo-600 text-white py-2 rounded hover:bg-indigo-700 transition disabled:opacity-50"
            >
              {loading ? 'Saving PIN...' : 'Set PIN & Login'}
            </button>
          </>
        )}
      </div>
    </div>
  );
}