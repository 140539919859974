import { useState, useEffect, useRef } from "react";
import { Check, X, Filter, Plus, Minus, Loader, ArrowLeft } from "lucide-react";
import axios from "axios";
import { Switch } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'
import AceEditor from "react-ace";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { host } from "../utilities/util";
import { StockTag } from "./StockTooltipComponent";
import { CalendarDays, Clock } from "lucide-react";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { TextField } from '@mui/material';


export default function FilterInterface() {
  const navigate = useNavigate();
  const [filters, setFilters] = useState([]);
  const [availableFilters, setAvailableFilters] = useState([]);
  const fetchFiltersCalled = useRef(false);
  const [applyMultiple, setApplyMultiple] = useState(false);
  const [enableBacktest, setEnableBacktest] = useState(false);
  const [backtestDate, setBacktestDate] = useState(null);
  const [codeInput, setCodeInput] = useState(`def evaluate_condition2():
    """
    Evaluates the given logical condition based on global variable values.
    Returns "Mom-5mtf" if the condition is met, otherwise returns "No".
    """

    if L3_1mtf_3CH_72_156_54_Gp > 30 and L3_1mtf_12_26_9_FL_CUR < 0 and (L3_1mtf_12_26_9_FL_PAST2 - L3_1mtf_12_26_9_FL_PAST1) <= (L3_1mtf_12_26_9_FL_PAST1 - L3_1mtf_12_26_9_FL_CUR):
        return "Mom-5mtf"
    else:
        return "No"
`);
  const [filteredStocks, setFilteredStocks] = useState([]);
  const [unfilteredStocks, setUnfilteredStocks] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [variableSuggestions, setVariableSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  // ✅ Show Toast Notification
  const showToast = (message, type = "info") => {
    if (type === "success") toast.success(message);
    else if (type === "error") toast.error(message);
    else if (type === "warning") toast.warning(message);
    else toast.info(message);
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  // Fetch available filters from the API on component mount
  useEffect(() => {
    if (fetchFiltersCalled.current) return;  // ✅ Prevent duplicate calls
    fetchFiltersCalled.current = true;

    const fetchFilters = async () => {
      try {
        toast.info("Fetching filters...");
        const response = await axios.get(`${host}get-filters`);

        if (response.data.status === "success") {
          const filters = response?.data?.data?.filters || [];
          setAvailableFilters(filters);
          toast.success("Filters loaded successfully!");
        } else {
          toast.error(response.data.message || "Failed to load filters.");
        }
      } catch (error) {
        toast.error("Error fetching filters!");
      }
    };

    const fetchVariableNames = async () => {
      try {
        const response = await axios.get(`${host}get-filter-variables`);

        if (response.data.status === "success") {
          const variables = response?.data?.data?.variables || [];
          setVariableSuggestions(Array.isArray(variables) ? variables : []);
        } else {
          setVariableSuggestions([]);
          toast.error(response.data.message || "Failed to load variable names.");
        }
      } catch (error) {
        setVariableSuggestions([]);
        toast.error("Error fetching variable names!");
      }
    };

    fetchVariableNames();
    fetchFilters();
  }, []);



  const customCompleter = {
    getCompletions: (editor, session, pos, prefix, callback) => {
      if (!prefix) return callback(null, []);  // No suggestions if prefix is empty

      // Ensure variableSuggestions is an array before calling map()
      const suggestions = (Array.isArray(variableSuggestions) ? variableSuggestions : []).map((variable) => ({
        caption: variable,  // Displayed in suggestions list
        value: variable,    // Inserted when selected
        meta: "variable"    // Category shown in the dropdown
      }));

      callback(null, suggestions);
    }
  };

  useEffect(() => {
    const langTools = window.ace.acequire("ace/ext/language_tools");
    langTools.addCompleter(customCompleter);
  }, [variableSuggestions]);

  // Handle adding a custom filter via the editor
  const handleAddFilter = async () => {
    if (!codeInput.trim()) return;

    // Extract all function definitions
    const functionDefs = codeInput
      .split(/(?=^def\s+)/gm)
      .map(def => def.trim())
      .filter(def => def.startsWith("def"));

    if (!functionDefs || functionDefs.length === 0) {
      showToast("No valid function definitions found!", "warning");
      return;
    }

    const functions = functionDefs.map(codeBlock => {
      const nameMatch = codeBlock.match(/def\s+([a-zA-Z_][a-zA-Z0-9_]*)\s*\(/);
      const name = nameMatch?.[1] || "unknown_func";

      const variables = extractVariablesFromFunctionCode(codeBlock, name);
      return {
        function_name: name,
        function_code: codeBlock,
        variables
      };
    });

    // 🔹 Create group name by joining function names (can also be user-defined later)
    const groupName = functions.map(f => f.function_name).join("_");

    const payload = applyMultiple
      ? {
        apply_multiple: true,
        function_name: groupName, // used as group_name in backend
        functions
      }
      : {
        function_name: functions[0].function_name,
        function_code: functions[0].function_code,
        variables: functions[0].variables,
        apply_multiple: false
      };

    try {
      showToast("Adding filter...", "info");

      const response = await axios.post(`${host}save-filter`, payload);
      const { status, message, data } = response.data;

      if (status === "success") {
        showToast(message || "Filter added successfully!", "success");

        if (applyMultiple) {
          const newGroup = {
            _id: data.group_id,
            function_name: groupName,
            function_code: functions.map(f => f.function_code).join("\n\n"),
            isGroup: true,
            filter_ids: data.filter_ids
          };
          setAvailableFilters(prev => [...prev, newGroup]);
          setSelectedFilter(newGroup);
        } else {
          const newFilter = {
            _id: data.filter_id,
            function_name: functions[0].function_name,
            function_code: functions[0].function_code,
            isGroup: false
          };
          setAvailableFilters(prev => [...prev, newFilter]);
          setSelectedFilter(newFilter);
        }

        setCodeInput("");
      } else {
        showToast(message || "Failed to add filter.", "error");
      }

    } catch (error) {
      const msg = error?.response?.data?.message || "Unexpected error occurred while adding filter!";
      showToast(msg, "error");
    }
  };


  // Helper to extract variables
  const extractVariablesFromFunctionCode = (code, currentFunctionName) => {
    const noStrings = code
      .replace(/("""[\s\S]*?""")|('''[\s\S]*?''')|(["'])(?:(?!\3)[^\\]|\\.)*?\3/g, "");

    const tokens = noStrings
      .split(/[^A-Za-z0-9_]+/)
      .filter((token) => /^[A-Za-z_][A-Za-z0-9_]*$/.test(token));

    const keywords = new Set([
      "def", "if", "else", "return", "and", "or", "not", "True", "False"
    ]);

    return [...new Set(tokens.filter(
      (t) =>
        !keywords.has(t) &&
        !t.startsWith("evaluate_") &&
        t !== currentFunctionName
    ))];
  };



  const handleFilterToggle = (filterId, function_code) => {
    setFilters((prev) =>
      prev.includes(filterId)
        ? prev.filter((id) => id !== filterId) // Remove filter from selected filters
        : [...prev, filterId] // Add filter to selected filters
    );

    // Show the function code of the selected filter and ensure only one filter code is displayed
    setSelectedFilter((prev) =>
      prev && prev._id === filterId ? null : { _id: filterId, function_code }
    );
  };

  // Handle removing a filter from both selected filters and available filters
  const handleRemoveFilter = async () => {
    try {
      if (filters.length === 0) return;

      showToast("Removing filters...", "info");

      const response = await axios.delete(`${host}remove-filter`, {
        data: { filters },
        headers: { "Content-Type": "application/json" },
      });

      const { status, message } = response.data;

      if (status === "success") {
        showToast(message || "Filter removed successfully!", "success");

        // Update frontend state
        setFilters((prev) => prev.filter((id) => !filters.includes(id)));
        setAvailableFilters((prev) =>
          prev.filter((filter) => !filters.includes(filter._id))
        );
        if (filters.includes(selectedFilter?._id)) {
          setSelectedFilter(null);
        }
      } else {
        showToast(message || "Failed to remove filters.", "error");
      }
    } catch (error) {
      const msg =
        error?.response?.data?.message || "Unexpected error occurred while removing filter!";
      showToast(msg, "error");
    }
  };

  const applyFilters = async () => {
    if (filters.length === 0) {
      showToast("Please select at least one filter!", "warning");
      return;
    }

    setLoading(true);
    showToast("Applying filters...", "info");

    try {
      const payload = {
        filterIds: filters,
      };

      if (enableBacktest && backtestDate) {
        payload.backtest_at = backtestDate.toISOString();
      }

      const response = await axios.post(`${host}apply-filters`, payload);
      const { status, message, data } = response.data;

      if (status !== "success" || !data) {
        showToast(message || "Invalid response from server!", "error");
        return;
      }

      setFilteredStocks(data.passed_stocks || []);
      setUnfilteredStocks(data.failed_stocks || []);

      showToast(message || "Filters applied successfully!", "success");
    } catch (error) {
      setFilteredStocks([]);
      setUnfilteredStocks([]);
      const msg =
        error?.response?.data?.message ||
        "Unexpected error occurred while applying filters!";
      showToast(msg, "error");
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <div className="min-h-screen bg-gray-50 p-4 md:p-6 grid grid-cols-1 md:grid-cols-2 grid-rows-[auto_auto_auto_auto] md:grid-rows-[1fr_1fr] gap-4 md:gap-6 overflow-auto">
        {/* 🔹 Back to Dashboard */}
        <div className="absolute top-2 left-4 z-50">
          <button
            onClick={() => navigate("/dashboard")}
            className="text-blue-600 hover:text-blue-800 flex items-center space-x-1"
          >
            <ArrowLeft size={18} />
            <span className="text-sm md:text-base">Back to Dashboard</span>
          </button>
        </div>

        {/* 🔄 Loading Overlay */}
        {loading && (
          <div className="absolute inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
            <div className="flex items-center space-x-2 text-white text-lg">
              <Loader className="animate-spin h-6 w-6" />
              <span>Applying filters, please wait...</span>
            </div>
          </div>
        )}

        {/* Available Filters */}
        <div className="bg-white rounded-lg shadow flex flex-col w-full h-[500px] overflow-hidden">
          <div className="px-4 py-3 border-b flex justify-between items-center">
            <h2 className="text-lg md:text-xl font-semibold flex items-center">
              <Filter className="mr-2 h-5 w-5" />
              Available Filters
            </h2>
            <div className="flex items-center space-x-2 text-xs text-gray-600">
              <label htmlFor="enableBacktest" className="flex items-center cursor-pointer">
                <input
                  id="enableBacktest"
                  type="checkbox"
                  checked={enableBacktest}
                  onChange={() => setEnableBacktest(!enableBacktest)}
                  className="mr-2"
                />
                Backtest
              </label>
            </div>
          </div>

          <div className="p-3 space-y-2 flex-1 overflow-auto">
            {availableFilters.length > 0 ? (
              availableFilters.map((filter) => (
                <div
                  key={filter._id}
                  className="flex items-center p-2 rounded-md hover:bg-gray-50 cursor-pointer"
                  onClick={() => handleFilterToggle(filter._id, filter.function_code)}
                >
                  <div
                    className={`w-5 h-5 border rounded-md mr-3 flex items-center justify-center ${filters.includes(filter._id)
                      ? "bg-blue-500 border-blue-500"
                      : "bg-white border-gray-300"
                      }`}
                  >
                    {filters.includes(filter._id) && <Check className="h-4 w-4 text-white" />}
                  </div>
                  <span className="text-sm">{filter.function_name}</span>
                </div>
              ))
            ) : (
              <div className="text-gray-500 text-sm text-center mt-4">
                No filters available. Please add a filter to begin.
              </div>
            )}

            {selectedFilter && (
              <div className="bg-white rounded-lg shadow p-4 mt-4">
                <h3 className="text-lg md:text-xl font-semibold mb-2">Function Code</h3>
                <pre className="bg-gray-100 p-4 rounded-md whitespace-pre-wrap max-h-60 overflow-auto text-sm">
                  {selectedFilter.function_code}
                </pre>
              </div>
            )}

            {/* 🧪 Backtest datetime picker */}
            {enableBacktest && (
              <div className="bg-gray-50 border rounded-md p-3 space-y-2 mt-2">
                <label className="text-sm font-medium flex items-center text-gray-600">
                  <CalendarDays className="mr-2 w-4 h-4" />
                  Select Backtest Date & Time
                </label>

                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DateTimePicker
                    label={null}
                    value={backtestDate}
                    onChange={(newDate) => setBacktestDate(newDate)}
                    maxDateTime={new Date()}
                    minutesStep={15}
                    ampm
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        className="!text-sm !bg-white !rounded-md"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            )}
          </div>

          <div className="p-4 border-t">
            <button
              className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center justify-center"
              onClick={applyFilters}
            >
              <Filter className="mr-2 h-4 w-4" />
              {enableBacktest ? "Backtest Strategy" : "Apply Filters"}
            </button>
          </div>
        </div>


        {/* Create Filters */}
        <div className="bg-white rounded-lg shadow flex flex-col w-full h-[400px] overflow-hidden">
          <div className="px-4 py-3 border-b">
            <div className="flex items-center justify-between">
              <h2 className="text-lg md:text-xl font-semibold flex items-center">
                <Plus className="mr-2 h-5 w-5" />
                Create Filters
              </h2>

              {/* Toggle Switch */}
              <div className="flex items-center space-x-3">
                <span className="text-sm text-gray-800">Apply Multiple</span>
                <Switch
                  checked={applyMultiple}
                  onChange={setApplyMultiple}
                  className={classNames(
                    applyMultiple ? 'bg-blue-600' : 'bg-gray-300',
                    'relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 focus:outline-none'
                  )}
                >
                  <span
                    className={classNames(
                      applyMultiple ? 'translate-x-6' : 'translate-x-1',
                      'inline-block h-4 w-4 transform rounded-full bg-white transition-transform'
                    )}
                  />
                </Switch>
              </div>
            </div>
          </div>

          <div className="p-3 flex flex-col flex-1 overflow-auto">
            <div className="w-full" style={{ height: "250px" }}>
              <AceEditor
                placeholder="Write your Python code here"
                mode="python"
                theme="github"
                name="pythonEditor"
                onLoad={(editor) => console.log("Editor loaded")}
                onChange={(newCode) => setCodeInput(newCode)}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={codeInput}
                width="100%"
                height="100%"
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
              />
            </div>
            <div className="flex flex-col sm:flex-row gap-2 mt-4">
              <button
                className="w-full sm:w-auto px-4 py-2 text-sm bg-green-600 hover:bg-green-700 text-white rounded-md flex items-center justify-center"
                onClick={handleAddFilter}
              >
                <Plus className="mr-2 h-4 w-4" />
                Add
              </button>
              <button
                className="w-full sm:w-auto px-4 py-2 text-sm bg-red-600 hover:bg-red-700 text-white rounded-md flex items-center justify-center"
                onClick={handleRemoveFilter}
              >
                <Minus className="mr-2 h-4 w-4" />
                Remove
              </button>
            </div>
          </div>
        </div>

        {/* Filtered Stocks */}
        {/* Filtered Stocks */}
        <div className="bg-white rounded-lg shadow w-full max-h-[300px] overflow-auto">
          {/* Sticky Header */}
          <div className="px-4 py-3 border-b sticky top-0 bg-white z-10">
            <h2 className="text-lg md:text-xl font-semibold flex items-center">
              <Check className="mr-2 h-5 w-5" />
              Filtered Stocks
            </h2>
          </div>

          {/* Proper Scrollable Area with Spacer */}
          <div className="p-3 pt-6"> {/* pt-6 = space for tooltip above top stocks */}
            <div className="flex flex-wrap gap-2 ml-4 mb-4">
              {filteredStocks.map((stock) => (
                <StockTag key={stock.symbol} stock={stock} isPassed={true} />
              ))}
            </div>
          </div>
        </div>


        {/* Unfiltered Stocks */}
        <div className="bg-white rounded-lg shadow w-full max-h-[300px] overflow-auto">
          {/* Sticky Header */}
          <div className="px-4 py-3 border-b sticky top-0 bg-white z-10">
            <h2 className="text-lg md:text-xl font-semibold flex items-center">
              <X className="mr-2 h-5 w-5" />
              Stocks That Do Not Match Filter Criteria
            </h2>
          </div>

          {/* Scrollable content with spacing top/bottom */}
          <div className="p-3 pt-6 pb-6">
            <div className="flex flex-wrap gap-2 ml-4 mb-4">
              {unfilteredStocks.map((stock) => (
                <StockTag key={stock.symbol} stock={stock} isPassed={false} />
              ))}
            </div>
          </div>
        </div>

      </div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        style={{ zIndex: 9999, position: "fixed" }}
      />
    </>
  );
}






function Sidebar({
  onBuy,
  onStartAutoBuy,
  onKillAllOrders,
  onConfigureAutoCancel,
  onSendAlert,
}) {
  return (
    <div className="w-60 bg-white shadow-md h-full p-4 flex flex-col space-y-6">
      <div>
        <h2 className="text-lg font-bold mb-2">Manage Orders</h2>
        <button
          onClick={onBuy}
          className="block w-full text-left px-3 py-2 rounded-md hover:bg-gray-100 text-sm mb-2"
        >
          Buy
        </button>
        <button
          onClick={onStartAutoBuy}
          className="block w-full text-left px-3 py-2 rounded-md hover:bg-gray-100 text-sm"
        >
          Start Auto-Buy
        </button>
      </div>

      <div>
        <h2 className="text-lg font-bold mb-2">Risk Management</h2>
        <button
          onClick={onKillAllOrders}
          className="block w-full text-left px-3 py-2 rounded-md hover:bg-gray-100 text-sm mb-2"
        >
          Kill All Orders
        </button>
        <button
          onClick={onConfigureAutoCancel}
          className="block w-full text-left px-3 py-2 rounded-md hover:bg-gray-100 text-sm"
        >
          Auto-Cancel Trades
        </button>
      </div>

      <div>
        <h2 className="text-lg font-bold mb-2">Alerts</h2>
        <button
          onClick={() => onSendAlert("Test alert via SMS/WhatsApp!")}
          className="block w-full text-left px-3 py-2 rounded-md hover:bg-gray-100 text-sm"
        >
          Send Test Alert
        </button>
      </div>
    </div>
  );
}