import { useState } from 'react';
import axios from 'axios';

type TokenType = 'operational' | 'broker';

interface FormState {
  operationalToken: string;
  brokerName: string;
  tokenValue: string;
  targetUserId: string;
}

export function useTokenManager(currentUserId: string, userRole: string) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isAdmin = userRole === 'admin';

  const [formData, setFormData] = useState<FormState>({
    operationalToken: '',
    brokerName: '',
    tokenValue: '',
    targetUserId: currentUserId,
  });

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => {
    setFormData({
      operationalToken: '',
      brokerName: '',
      tokenValue: '',
      targetUserId: currentUserId,
    });
    setIsModalOpen(false);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const submitToken = async (tokenType: TokenType) => {
    try {
      setIsSubmitting(true);

      const payload: Record<string, any> = {
        tokenType,
        userId: isAdmin ? (formData.targetUserId || currentUserId) : currentUserId,
      };

      if (tokenType === 'operational') {
        payload.tokenValue = formData.operationalToken;
      } else if (tokenType === 'broker') {
        if (!formData.brokerName) {
          console.warn('Broker name is required.');
          return;
        }
        payload.tokenValue = formData.tokenValue;
        payload.brokerName = formData.brokerName;
      }

      const res = await axios.post('/api/save-token', payload);
      console.log('✅ Token saved:', res.data);
      closeModal();
    } catch (err: any) {
      console.error('❌ Failed to save token:', err?.response?.data || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isModalOpen,
    openModal,
    closeModal,
    formData,
    handleChange,
    submitToken,
    isAdmin,
    isSubmitting,
  };
}
